import styled from "styled-components";
import Logo from "../ui-v2/Logo";
import Dropdown from "../ui-v2/Dropdown";
import DropdownUser from "../ui-v2/DropdownUser";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { Suspense, useEffect, useRef, useState } from "react";
import { ROUTES } from "../../router";
import {
  TOrganization,
  TOrganizationData,
} from "../../redux/reducers/organizations";
import {
  TInvoiceData,
  TInvoiceEntryStatus,
  TPaymentSlipData,
  setInvoiceCounters,
  setInvoices,
  setInvoicesUpdatedAt,
  setPaymentSlipCounters,
  setPaymentSlips,
  setPaymentSlipsUpdatedAt,
  setSelectOrganizationState,
  setSelectedOrganizationWithPermissionsState,
} from "../../redux/reducers/globalState";
import { /* Link,  */ useNavigate } from "react-router-dom";
import { TUserData } from "../../redux/reducers/userData";
import {
  getQuerystringParams,
  handleGetDataFromLocalStorage,
  handleParseQuerystrings,
  handleParseToDateCalendarOptions,
  handlePopulateQuerystring,
  handleRevertTranslateCalendarOptions,
  handleSetDataToLocalStorage,
  handleSetEndDate,
} from "../../utils/miscellaneous";
import { localStorageConstants } from "../../utils/constants";
import apiService from "../../services/api";
import { format } from "date-fns/format";
import { sub } from "date-fns/sub";
// import { parse } from "date-fns";
import {
  CALENDAR_CONTENT_OPTIONS,
  CALENDAR_SLIP_CONTENT_OPTIONS,
  TCalendarMenuOptions,
} from "../ui-v2/DropdownFilter/Menu";
import { TAccount } from "../../redux/reducers/account";
import { handleSortQuerystring } from "../../utils/formatters";
import Button from "../ui-v2/Button";
import QuickAccessMenu from "../ui-v2/Menus/QuickAccessMenu";
import axios, { CancelTokenSource } from "axios";

const Container = styled.header`
  display: flex;
  justify-content: space-between;

  background: var(--color-light-0);
  width: 100vw;
  height: 72px;

  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  .right-container {
    display: flex;
    align-items: center;

    & > div:first-child {
      width: 328px;
      margin-right: 16px;

      & > div > button {
        height: 40px;
        min-height: unset;

        &::before {
          top: 5px;
        }

        & svg {
          top: 8px;
        }

        & p.content {
          top: 15px;
        }
      }
    }

    & > button {
      width: 40px;
      height: 40px;
      margin-right: 16px;

      &:active {
        background: var(--color-light-100);
      }

      &:hover {
        background: var(--color-light-50);
      }

      & svg {
        width: 22px;
        height: 22px;
      }
    }
  }
`;

const Subcontainer = styled.div`
  display: flex;
  align-items: center;

  margin-left: 24px;

  .homeLink {
    padding: 0;
    cursor: pointer;

    &:hover {
      background: transparent;
    }
  }

  .separator {
    margin: 0 24px;
    height: 40px;
    width: 1px;
    background: var(--color-light-200);
  }

  .tab-area {
    display: flex;

    button {
      font-family: var(--font-paragraph-default);
      color: var(--color-text-labels);
      font-size: 16px;
      height: 71px;
      border-radius: 8px 8px 0px 0px;
      cursor: pointer;

      &:nth-child(2) {
        margin-left: 8px;
      }

      &:hover {
        background: var(--color-light-100);
      }

      &.active {
        color: var(--color-main-rose);
        font-family: var(--font-paragraph-bold);
        border-bottom: 3px solid var(--color-main-rose);
      }
    }
  }

  // & > div {
  //   width: 328px;
  // }

  // & > *:nth-child(2) {
  //   margin-left: 12px;

  //   & button {
  //     border-radius: 4px;
  //   }
  // }
`;

const Header: React.FC = () => {
  const [organizations, setOrganizations] = useState<TOrganization[]>([]);
  const organizationsCountRef = useRef(0);
  const [, /* selectOrganization*/ setSelectOrganization] =
    useState<TOrganization | null>(null);

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [shoulShowQuickAccessMenu, setShouldShowQuickAccessMenu] =
    useState(false);

  const organizationRef = useRef<TOrganization[]>([]);
  const searchPhraseRef = useRef<string | undefined>();
  const quickAccessButtonRef = useRef<HTMLButtonElement | null>(null);

  const startDateRef = useRef<string | undefined>();
  const endDateRef = useRef<string | undefined>();

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const pageRef = useRef(1);
  const itemsPerPageRef = useRef(100);

  const dispatch = useTypedDispatch();

  const navigate = useNavigate();

  const [user, setUser] = useState<TUserData | null>(null);

  const handleOptions = (option: string) => {
    const result = organizations?.map((organization) =>
      option === "companyName" ? organization.company_name : organization.cnpj
    ) ?? [""];
    return result;
  };

  const handleDispatchInvoicesStateUpdate = (
    invoicesResponse: TInvoiceData,
    entryStatus?: TInvoiceEntryStatus
  ) => {
    // console.log("dispatching invoice state update: ", invoicesResponse);
    dispatch(
      setInvoices({
        invoices: invoicesResponse,
        entryStatus: entryStatus,
      })
    );
  };

  const handleDispatchPaymentSlipsStateUpdate = (
    paymentSlipResponse: TPaymentSlipData,
    entryStatus?: TInvoiceEntryStatus
  ) => {
    dispatch(
      setPaymentSlips({
        paymentSlips: paymentSlipResponse,
        entryStatus: entryStatus,
      })
    );
  };

  const fetchInvoices = async (
    organizationUuid: string,
    entryStatus?: TInvoiceEntryStatus
  ) => {
    if (organizationUuid && organizationUuid.length > 0) {
      const queryStrings = handleParseQuerystrings(window.location.search);

      let startDate = format(sub(new Date(), { days: 30 }), "yyyy-MM-dd");
      let endDate = handleSetEndDate(
        queryStrings.period
          ? handleRevertTranslateCalendarOptions(
              queryStrings.period[0] as TCalendarMenuOptions
            ) ?? ""
          : ""
      );

      endDateRef.current = handleSetEndDate(
        queryStrings.period
          ? handleRevertTranslateCalendarOptions(
              queryStrings.period[0] as TCalendarMenuOptions
            ) ?? ""
          : ""
      );

      const queryStringParams = getQuerystringParams();

      const searchStrings = handlePopulateQuerystring();

      if (queryStrings.period) {
        const translatedPeriod = handleRevertTranslateCalendarOptions(
          queryStrings.period[0] as TCalendarMenuOptions
        );

        let tempStartDate: Date | undefined = sub(new Date(), { days: 30 });

        if (window.location.href.includes("/invoices/")) {
          if (
            translatedPeriod &&
            CALENDAR_CONTENT_OPTIONS.some((calendarOption) =>
              calendarOption.toLowerCase().includes(translatedPeriod)
            )
          ) {
            tempStartDate = handleParseToDateCalendarOptions(
              queryStrings.period[0] as TCalendarMenuOptions
            );
          } else {
            searchStrings.set("period", "last-30-days");
          }
        }

        if (window.location.href.includes("/payment-slip/")) {
          if (
            translatedPeriod &&
            CALENDAR_SLIP_CONTENT_OPTIONS.includes(translatedPeriod)
          ) {
            tempStartDate = handleParseToDateCalendarOptions(
              queryStrings.period[0] as TCalendarMenuOptions
            );
          } else {
            searchStrings.set("period", "all-time");
          }
        }

        if (tempStartDate === undefined) {
          startDate = "";
          startDateRef.current = "";
        } else {
          startDate = format(tempStartDate, "yyyy-MM-dd");
          startDateRef.current = format(tempStartDate, "yyyy-MM-dd");
        }

        endDate = handleSetEndDate(translatedPeriod ?? "");
        endDateRef.current = handleSetEndDate(translatedPeriod ?? "");
      } else if (queryStrings.start_date && queryStrings.end_date) {
        startDate = queryStrings.start_date[0];
        startDateRef.current = queryStrings.start_date[0];
        endDate = queryStrings.end_date[0];
        endDateRef.current = queryStrings.end_date[0];
      }

      if (queryStrings.page) {
        pageRef.current = parseInt(queryStrings.page[0]);
      }

      if (queryStrings.items_per_page) {
        itemsPerPageRef.current = parseInt(queryStrings.items_per_page[0]);
      }

      handleSetDataToLocalStorage(
        localStorageConstants.INVOICE_FILTER_START_DATE,
        startDate
      );

      handleSetDataToLocalStorage(
        localStorageConstants.INVOICE_FILTER_END_DATE,
        endDate
      );

      const hasPayableHandler = () => {
        if (queryStrings.has_accounts_payable) {
          if (
            queryStrings.has_goods_receipt &&
            queryStrings.has_goods_receipt[0] === "false" &&
            queryStrings.has_accounts_payable[0] === "false"
          ) {
            return false;
          } else {
            return queryStrings.has_accounts_payable[0] === "true"
              ? true
              : undefined;
          }
        }
        return undefined;
      };

      const hasGoodsHandler = () => {
        if (queryStrings.has_goods_receipt) {
          if (
            queryStrings.has_accounts_payable &&
            queryStrings.has_accounts_payable[0] === "false" &&
            queryStrings.has_goods_receipt[0] === "false"
          ) {
            return false;
          } else {
            return queryStrings.has_goods_receipt[0] === "true"
              ? true
              : undefined;
          }
        }
        return undefined;
      };

      if (!queryStrings.period) {
        if (!queryStrings.start_date && !queryStrings.end_date) {
          if (window.location.href.includes("/invoices/")) {
            searchStrings.set("period", "last-30-days");
          }
          if (window.location.href.includes("/payment-slip/")) {
            searchStrings.set("period", "all-time");
          }
        } else if (startDate.length > 0) {
          searchStrings.set("start_date", startDate);
          searchStrings.set("end_date", endDate);
        }
      }

      searchStrings.set("organization_id", organizationUuid);
      if (entryStatus)
        searchStrings.set("launch_status", entryStatus.toString());

      const hasAccountsPayable = hasPayableHandler();
      const hasGoods = hasGoodsHandler();

      if (hasAccountsPayable !== undefined)
        searchStrings.set(
          "has_accounts_payable",
          hasAccountsPayable.toString()
        );

      if (hasGoods !== undefined)
        searchStrings.set("has_goods_receipts", hasGoods.toString());

      if (queryStrings.search)
        searchStrings.set("search", queryStrings.search[0]);

      searchStrings.set("page", pageRef.current.toString());
      searchStrings.set("items_per_page", itemsPerPageRef.current.toString());

      navigate("./" + handleSortQuerystring(searchStrings));

      if (cancelTokenSource.current) {
        apiService
          .getInvoiceCounters(
            {
              organizationUuid,
              date_issue_after:
                startDateRef.current /* queryStringParams.startDate */,
              date_issue_before:
                endDateRef.current /* queryStringParams.endDate */,
              has_accounts_payable: queryStringParams.hasPayable,
              has_goods_receipt: queryStringParams.hasGoods,
            },
            cancelTokenSource.current.token
          )
          .then((response) => {
            if (response) {
              dispatch(setInvoiceCounters({ counters: response.data }));
            }
          });
      }

      apiService
        .getInvoices(
          {
            organizationUuid,
            launch_status: entryStatus,
            date_issue_before:
              startDateRef.current /* startDate.length > 0 ? startDate : undefined */,
            date_issue_after:
              endDateRef.current /* startDate.length > 0 ? endDate : undefined */,
            has_accounts_payable: hasPayableHandler(),
            has_goods_receipt: hasGoodsHandler(),
            search: queryStrings.search ? queryStrings.search[0] : undefined,
            page: pageRef.current,
            per_page: itemsPerPageRef.current,
          },
          (cancelTokenSource.current as CancelTokenSource).token
        )
        .then((response) => {
          // console.log(
          //   "search param: ",
          //   queryStrings.search,
          //   ", entry status: ",
          //   entryStatus,
          //   ", start date: ",
          //   startDate,
          //   ", end date: ",
          //   endDate,
          //   ", response: ",
          //   response
          // );
          if (response) {
            const invoicesResponseData = response.data as TInvoiceData;
            handleDispatchInvoicesStateUpdate(
              invoicesResponseData,
              entryStatus
            );
          }
        });
    }
  };

  const fetchPaymentSlips = async (
    organizationUuid: string,
    entryStatus?: TInvoiceEntryStatus
  ) => {
    if (organizationUuid?.length > 0) {
      const queryStringParams = getQuerystringParams();

      handleSetDataToLocalStorage(
        localStorageConstants.INVOICE_FILTER_START_DATE,
        queryStringParams.startDate
      );

      handleSetDataToLocalStorage(
        localStorageConstants.INVOICE_FILTER_END_DATE,
        queryStringParams.endDate
      );

      itemsPerPageRef.current = queryStringParams.itemsPerPage;
      pageRef.current = queryStringParams.page;

      // searchStrings.set("organization_id", organizationUuid);
      // if (entryStatus)
      //   searchStrings.set("launch_status", entryStatus.toString());
      // if (startDate && startDate.length > 0 && endDate) {
      //   searchStrings.set("start_date", startDate);
      //   searchStrings.set("end_date", endDate);
      // }

      // if (queryStrings.search)
      //   searchStrings.set("search", queryStrings.search[0]);

      // searchStrings.set("page", pageRef.current.toString());
      // searchStrings.set("items_per_page", itemsPerPageRef.current.toString());

      // navigate("./" + handleSortQuerystring(searchStrings));

      console.log("querystring params: ", queryStringParams);

      if (cancelTokenSource.current) {
        apiService
          .getPaymentSlipCounters(
            {
              organizationUuid,
              due_date_after: queryStringParams.endDate,
              due_date_before: queryStringParams.startDate,
              has_accounts_payable: queryStringParams.hasPayable,
              has_goods_receipt: queryStringParams.hasGoods,
            },
            cancelTokenSource.current.token
          )
          .then((response) => {
            if (response) {
              dispatch(setPaymentSlipCounters({ counters: response.data }));
            }
          });
      }

      apiService
        .getPaymentSlips(
          {
            organizationUuid,
            launch_status:
              queryStringParams.launchStatus as TInvoiceEntryStatus,
            due_date_after: queryStringParams.endDate,
            due_date_before: queryStringParams.startDate,
            has_accounts_payable: queryStringParams.hasPayable,
            has_goods_receipt: queryStringParams.hasGoods,
            search: queryStringParams.search,
            page: pageRef.current,
            per_page: itemsPerPageRef.current,
          },
          (cancelTokenSource.current as CancelTokenSource).token
        )
        .then((response) => {
          if (response) {
            const paymentSlipResponseData = response.data as TPaymentSlipData;
            handleDispatchPaymentSlipsStateUpdate(
              paymentSlipResponseData,
              entryStatus
            );
          }
        });
    }
  };

  const handleFetchInvoicesByAllEntryStatus = async (
    organizationUuid: string
  ) => {
    // const localCurrentEntryStatus = handleGetDataFromLocalStorage(
    //   localStorageConstants.CURRENT_ENTRY_STATUS
    // ) as TInvoiceEntryStatus;

    const queryStrings = handleParseQuerystrings(window.location.search);

    const queryStringParams = getQuerystringParams();

    if (organizationUuid) {
      if (cancelTokenSource.current) {
        apiService
          .getInvoiceCounters(
            {
              organizationUuid,
              date_issue_after: queryStringParams.startDate,
              date_issue_before: queryStringParams.endDate,
              has_accounts_payable: queryStringParams.hasPayable,
              has_goods_receipt: queryStringParams.hasGoods,
            },
            cancelTokenSource.current.token
          )
          .then((response) => {
            dispatch(setInvoiceCounters({ counters: response.data }));
          });
      }

      if (
        /* localCurrentEntryStatus */
        queryStrings.launch_status
      ) {
        setTimeout(async () => {
          const launchStatus = parseInt(queryStrings.launch_status[0]);
          await fetchInvoices(
            organizationUuid,
            /* localCurrentEntryStatus */ launchStatus === 40
              ? undefined
              : (launchStatus as TInvoiceEntryStatus)
          ).then(() => {
            apiService
              .getInvoiceUpdatedAt(
                organizationUuid,
                (cancelTokenSource.current as CancelTokenSource).token
              )
              .then((response: any) => {
                if (response) {
                  dispatch(
                    setInvoicesUpdatedAt({
                      responseStatus: response.status,
                      date: response.data.updated_at,
                    })
                  );
                }
              });
          });
        }, 60);
      } else {
        setTimeout(async () => {
          await fetchInvoices(organizationUuid, 10).then(() => {
            apiService
              .getInvoiceUpdatedAt(
                organizationUuid,
                (cancelTokenSource.current as CancelTokenSource).token
              )
              .then((response: any) => {
                if (response) {
                  dispatch(
                    setInvoicesUpdatedAt({
                      responseStatus: response.status,
                      date: response.data.updated_at,
                    })
                  );
                }
              });
          });
        }, 50);
      }
    }
  };

  const handleFetchPaymentSlipsByAllEntryStatus = async (
    organizationUuid: string
  ) => {
    // const localCurrentEntryStatus = handleGetDataFromLocalStorage(
    //   localStorageConstants.CURRENT_ENTRY_STATUS
    // ) as TInvoiceEntryStatus;

    const queryStrings = handleParseQuerystrings(window.location.search);

    const queryStringParams = getQuerystringParams();
    if (organizationUuid) {
      if (cancelTokenSource.current) {
        apiService
          .getPaymentSlipCounters(
            {
              organizationUuid,
              due_date_after: queryStringParams.endDate,
              due_date_before: queryStringParams.startDate,
              has_accounts_payable: queryStringParams.hasPayable,
              has_goods_receipt: queryStringParams.hasGoods,
            },
            cancelTokenSource.current.token
          )
          .then((response) => {
            if (response) {
              dispatch(setPaymentSlipCounters({ counters: response.data }));
            }
          });
      }

      if (
        /* localCurrentEntryStatus */
        queryStrings.launch_status
      ) {
        setTimeout(async () => {
          const launchStatus = parseInt(queryStrings.launch_status[0]);

          await fetchPaymentSlips(
            organizationUuid,
            /* localCurrentEntryStatus */ launchStatus === 40
              ? undefined
              : (launchStatus as TInvoiceEntryStatus)
          ).then(() => {
            apiService
              .getPaymentSlipUpdatedAt(
                organizationUuid,
                (cancelTokenSource.current as CancelTokenSource).token
              )
              .then((response: any) => {
                if (response) {
                  dispatch(
                    setPaymentSlipsUpdatedAt({
                      responseStatus: response.status,
                      date: response.data.updated_at,
                    })
                  );
                }
              });
          });
        }, 60);
      } else {
        setTimeout(async () => {
          await fetchPaymentSlips(organizationUuid, 10).then(() => {
            apiService
              .getPaymentSlipUpdatedAt(
                organizationUuid,
                (cancelTokenSource.current as CancelTokenSource).token
              )
              .then((response: any) => {
                if (response) {
                  dispatch(
                    setPaymentSlipsUpdatedAt({
                      responseStatus: response.status,
                      date: response.data.updated_at,
                    })
                  );
                }
              });
          });
        }, 50);
      }
    }
  };

  const handleFetchOrganizations = async (searchPhrase?: string) => {
    const localSelectAccount = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ACCOUNT
    ) as TAccount;
    const localSelectOrganization = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ORGANIZATION
    ) as TOrganization;

    const queryStrings = handleParseQuerystrings(window.location.search);

    const searchString = handlePopulateQuerystring();

    let orgId = "";
    if (queryStrings.organization_id) {
      orgId = queryStrings.organization_id[0];
    } else if (localSelectOrganization) {
      orgId = localSelectOrganization.uuid;
    }
    if (orgId.length > 0) searchString.set("organization_id", orgId);

    cancelTokenSource.current = axios.CancelToken.source();

    if (localSelectAccount?.uuid) {
      const organizationsResponse = (
        await apiService.getOrganizations(
          localSelectAccount.uuid,
          cancelTokenSource.current.token,
          undefined,
          searchPhrase
        )
      ).data as TOrganizationData;

      organizationsCountRef.current = organizationsResponse.count;

      // if (searchPhrase) {
      //   setTimeout(async () => {
      //     organizationRef.current = await apiService.getAllOrganizations(
      //       searchPhrase
      //     );
      //   }, 40);
      // } else {
      //   organizationRef.current = await apiService.getAllOrganizations();
      // }

      if (searchPhrase) {
        setTimeout(async () => {
          organizationRef.current = (
            await apiService.getOrganizations(
              localSelectAccount.uuid,
              (cancelTokenSource.current as CancelTokenSource).token,
              undefined,
              searchPhrase
            )
          ).data.results;
        }, 500);
      } else {
        organizationRef.current = (
          await apiService.getOrganizations(
            localSelectAccount.uuid,
            cancelTokenSource.current.token
          )
        ).data.results;
      }

      // console.log("organizations: ", organizationRef.current);
      setOrganizations(organizationRef.current);

      if (localSelectOrganization) {
        let selectOrg = organizationRef.current.filter(
          (organizations) => organizations.uuid === localSelectOrganization.uuid
        );

        if (selectOrg.length > 0) {
          handleSetDataToLocalStorage(
            localStorageConstants.SELECT_ORGANIZATION,
            selectOrg[0]
          );
        }

        if (
          queryStrings.organization_id &&
          queryStrings.organization_id[0] !== localSelectOrganization.uuid
        ) {
          let qsOrganization = organizationRef.current.filter(
            (organizations) =>
              organizations.uuid === queryStrings.organization_id[0]
          );

          if (qsOrganization.length > 0) {
            handleSetDataToLocalStorage(
              localStorageConstants.SELECT_ORGANIZATION,
              qsOrganization[0]
            );

            searchString.set("organization_id", qsOrganization[0].uuid);
            window.location.href = "./" + handleSortQuerystring(searchString);

            const permissions = (
              await apiService.getOrganizationPermissions(
                qsOrganization[0].uuid,
                cancelTokenSource.current.token
              )
            ).data;

            dispatch(
              setSelectedOrganizationWithPermissionsState({
                selectedOrganization: {
                  ...qsOrganization[0],
                  permissions: permissions,
                },
              })
            );

            setSelectOrganization(qsOrganization[0]);
            await handleFetchInvoicesByAllEntryStatus(qsOrganization[0].uuid);
          }
        } else {
          navigate("./" + handleSortQuerystring(searchString));

          const permissions = (
            await apiService.getOrganizationPermissions(
              localSelectOrganization.uuid,
              cancelTokenSource.current.token
            )
          ).data;

          dispatch(
            setSelectedOrganizationWithPermissionsState({
              selectedOrganization: {
                ...localSelectOrganization,
                permissions: permissions,
              },
            })
          );

          setSelectOrganization(localSelectOrganization);

          // if (window.location.pathname.includes("payment-slip")) {
          await handleFetchPaymentSlipsByAllEntryStatus(
            localSelectOrganization.uuid
          );
          // } else {
          await handleFetchInvoicesByAllEntryStatus(
            localSelectOrganization.uuid
          );
          // }
        }
      } else {
        if (organizationsResponse.results[0]) {
          handleSetDataToLocalStorage(
            localStorageConstants.SELECT_ORGANIZATION,
            organizationsResponse.results[0]
          );

          navigate(
            "./?organization_id=" + organizationsResponse.results[0].uuid
          );

          const permissions = (
            await apiService.getOrganizationPermissions(
              organizationsResponse.results[0].uuid,
              cancelTokenSource.current.token
            )
          ).data;

          dispatch(
            setSelectedOrganizationWithPermissionsState({
              selectedOrganization: {
                ...organizationsResponse.results[0],
                permissions: permissions,
              },
            })
          );

          setSelectOrganization(organizationsResponse.results[0]);
          await handleFetchInvoicesByAllEntryStatus(
            organizationsResponse.results[0].uuid
          );
        } else {
          dispatch(
            setInvoices({
              invoices: { count: 0, results: [], current_page: 1, pages: [] },
              entryStatus: 10,
            })
          );
          dispatch(
            setPaymentSlips({
              paymentSlips: {
                count: 0,
                results: [],
                current_page: 1,
                pages: [],
              },
              entryStatus: 10,
            })
          );
        }
      }
      dispatch(setSelectOrganizationState());

      // setOrganizations(organizationRef.current);
    }
  };

  const handleMenuOnSelect = async (selected: string) => {
    const selectedOrganization = organizations?.find(
      (organization) =>
        organization.cnpj === selected || organization.company_name === selected
    ) as TOrganization;

    const localLastAccessedOrganizations = handleGetDataFromLocalStorage(
      localStorageConstants.LAST_ACCESSED_ORGANIZATIONS
    ) as TOrganization[];

    if (selectedOrganization) {
      handleSetDataToLocalStorage(
        localStorageConstants.SELECT_ORGANIZATION,
        selectedOrganization
      );
      // if (window.location.pathname.includes("payment-slip")) {
      handleFetchPaymentSlipsByAllEntryStatus(selectedOrganization.uuid);
      // } else {
      handleFetchInvoicesByAllEntryStatus(selectedOrganization.uuid);
      // }

      if (localLastAccessedOrganizations) {
        const repeatElementIndex = localLastAccessedOrganizations.findIndex(
          (lastAccessedOrg) =>
            lastAccessedOrg.uuid === selectedOrganization.uuid
        );
        if (repeatElementIndex === -1) {
          localLastAccessedOrganizations.unshift(selectedOrganization);
        } else {
          localLastAccessedOrganizations.splice(repeatElementIndex, 1);
          localLastAccessedOrganizations.unshift(selectedOrganization);
        }
      }
      handleSetDataToLocalStorage(
        localStorageConstants.LAST_ACCESSED_ORGANIZATIONS,
        localLastAccessedOrganizations ?? [selectedOrganization]
      );

      const permissions = (
        await apiService.getOrganizationPermissions(
          selectedOrganization.uuid,
          (cancelTokenSource.current as CancelTokenSource).token
        )
      ).data;

      dispatch(setSelectOrganizationState());

      dispatch(
        setSelectedOrganizationWithPermissionsState({
          selectedOrganization: {
            ...selectedOrganization,
            permissions: permissions,
          },
        })
      );

      const searchString = handlePopulateQuerystring();

      searchString.set("organization_id", selectedOrganization.uuid);

      navigate("./" + handleSortQuerystring(searchString));
    } else {
      if (localLastAccessedOrganizations) {
        handleSetDataToLocalStorage(
          localStorageConstants.LAST_ACCESSED_ORGANIZATIONS,
          []
        );
        window.location.reload();
      }
    }
  };

  // const handleOnSearch = (searchPhrase: string) => {
  //   searchPhraseRef.current = searchPhrase;
  //   setOrganizations(organizations);
  //   // handleFetchOrganizations(searchPhraseRef.current);
  // };

  useEffect(() => {
    setTimeout(() => {
      handleFetchOrganizations();

      const localUserData = handleGetDataFromLocalStorage(
        localStorageConstants.USER_DATA
      ) as TUserData;

      setUser(localUserData);

      if (window.location.pathname.includes("payment-slip")) {
        setActiveTabIndex(1);
      }
    }, 200);

    return () => {
      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    };
  }, []);

  return (
    <Container>
      <Subcontainer>
        <button
          className="homeLink"
          onClick={() => {
            // if (window.location.search.length > 0) {
            // if (selectOrganization)
            //   handleFetchInvoicesByAllEntryStatus(selectOrganization.uuid);
            // navigate("./");
            const queryStrings = handleParseQuerystrings(
              window.location.search
            );
            let searchString = "";
            if (queryStrings.organization_id) {
              searchString = queryStrings.organization_id[0];

              window.location.href =
                ROUTES.HOME + "?organization_id=" + searchString;
            }
            window.location.href = ROUTES.HOME;
            // } else {
            //   navigate(ROUTES.HOME);
            // }
          }}
        >
          <Suspense fallback={<div style={{ width: 191, height: 40 }}></div>}>
            <Logo />
          </Suspense>
        </button>
        <div
          className="separator"
          style={{
            visibility: window.location.pathname.includes("/settings/")
              ? "hidden"
              : "visible",
          }}
        />
        <div
          className="tab-area"
          style={{
            visibility: window.location.pathname.includes("/settings/")
              ? "hidden"
              : "visible",
          }}
        >
          <button
            className={activeTabIndex === 0 ? "active" : ""}
            role="button"
            onClick={() => {
              const url = new URL(window.location.href);
              url.searchParams.set("period", "last-30-days");
              url.searchParams.delete("start_date");
              url.searchParams.delete("end_date");
              url.searchParams.delete("search");
              setActiveTabIndex(0);
              navigate(ROUTES.HOME + url.search);
              handleFetchInvoicesByAllEntryStatus(
                url.searchParams.get("organization_id") as string
              );
            }}
          >
            Notas fiscais
          </button>
          <button
            className={activeTabIndex === 1 ? "active" : ""}
            role="button"
            onClick={() => {
              const url = new URL(window.location.href);
              url.searchParams.set("period", "all-time");
              url.searchParams.delete("start_date");
              url.searchParams.delete("end_date");
              url.searchParams.delete("search");
              setActiveTabIndex(1);
              navigate(ROUTES.PAYMENT_SLIP + url.search);
              handleFetchPaymentSlipsByAllEntryStatus(
                url.searchParams.get("organization_id") as string
              );
            }}
          >
            Boletos
          </button>
        </div>
      </Subcontainer>
      <div className="right-container">
        <Dropdown
          button={{
            variant: "labeled",
            labels: handleOptions("companyName"),
            placeholder: "Organização",
            showPlaceholder: false,
          }}
          menu={{
            variant: "labeled",
            labels: handleOptions("companyName"),
            options: handleOptions("cnpj"),
            search: true,
            optGroup: ["Últimos acessados", "Todos"],
            onSelect: handleMenuOnSelect,
            // searchPhraseOnChange: handleOnSearch,
            hasInfiniteScrolling: {
              variant: "organizations",
              totalElements: organizationsCountRef.current,
              onIntersect(data, elementsAmount) {
                organizationsCountRef.current = elementsAmount;
                if (organizations.length < data.length) {
                  setOrganizations(data);
                }
              },
              searchParam: searchPhraseRef.current,
            },
          }}
        />
        <Button
          onClick={() => {
            const quickAccessRef = document.getElementById(
              "quick-access-menu-button"
            );
            if (quickAccessRef)
              quickAccessButtonRef.current =
                quickAccessRef as HTMLButtonElement;
            setShouldShowQuickAccessMenu((before) => !before);
          }}
          icon="onlyIcon"
          iconType="layerGroup"
          variant="outline"
          id="quick-access-menu-button"
        />
        <QuickAccessMenu
          $ref={quickAccessButtonRef.current}
          $left={quickAccessButtonRef.current?.getBoundingClientRect().left}
          shouldShow={shoulShowQuickAccessMenu}
          onClickAway={() => {
            setShouldShowQuickAccessMenu(false);
          }}
        />
        {user && (
          <DropdownUser
            email={user?.email ?? ""}
            icon={(user && user.first_name[0] + user.last_name[0]) ?? ""}
            name={user?.first_name + " " + user?.last_name}
          />
        )}
      </div>
    </Container>
  );
};

export default Header;
